import React from "react"
import { Link } from "gatsby"
import "./footer.scss"
import Instagram from '../../svgs/instagram'
import Twitter from '../../svgs/twitter'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import LinkedIn from '../../svgs/linkedin'

const Footer = props => (
  <footer>
    <div className="container">
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-site-links">
            <Link to="/" className="logo">
              USLPA
            </Link>
            <nav>
              <ul className="footer-nav">
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/player-relations">Player Relations</Link>
                </li>
                <li>
                  <Link to="/resources">Resources</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <ul className="footer-social-links">
            <li>
              <OutboundLink href="https://twitter.com/USLPlayers" target="_blank" rel="noopener noreferrer">
                <Twitter className="icon" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://www.instagram.com/uslplayers/" target="_blank" rel="noopener noreferrer">
                <Instagram className="icon" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink href="https://www.linkedin.com/company/usl-players-association/" target="_blank" rel="noopener noreferrer">
                <LinkedIn className="icon" />
              </OutboundLink>
            </li>
          </ul>
        </div>
        <span className="copyright">© Copyright {new Date().getFullYear()}</span>
      </div>
    </div>
  </footer>
)

export default Footer
