import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "./header.scss"
import Hamburger from "../hamburger/hamburger"
import NavMenu from "../nav-menu/nav-menu"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "uslpa-logo-negative.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      className="header-logo"
      alt="USLPA Logo"
    />
  )
}

const SiteHeader = props => {
  const [menuOpen, setIsOpen] = useState(false)
  const menuToggle = () => setIsOpen(!menuOpen)

  const menuItems = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About",
      path: "/about",
    },
    {
      title: "Player Relations",
      path: "/player-relations",
    },
    {
      title: "Resources",
      path: "/resources",
    },
    {
      title: "News",
      path: "/news",
    },
    {
      title: "Contact Us",
      path: "/contact-us",
    },
    {
      title: "Onrise Mental Health Solutions",
      path: "https://onrise.care/get-care-now",
      ext: true
    },
  ]

  return (
    <header>
      <div className="container">
        <div className="header-wrapper">
          <Link to="/" className="logo">
            <Logo />
          </Link>
          <Hamburger menuToggle={menuToggle} active={menuOpen} />
          <NavMenu active={menuOpen} menuItems={menuItems} />
        </div>
      </div>
    </header>
  )
}

export default SiteHeader
