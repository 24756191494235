import React from "react"
import "./hamburger.scss"

const Hamburger = props => (
  <button
    className={
      "hamburger hamburger--arrowalt" + (props.active ? " is-active" : "")
    }
    type="button"
    aria-label="Menu"
    onClick={props.menuToggle}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner"></span>
    </span>
  </button>
)

export default Hamburger
