import React from "react"
import { Link } from "gatsby"
import "./nav-menu.scss"

const NavMenu = props => {
  return (
    <div className={"nav-container" + (props.active ? " is-open" : "")}>
      <nav>
        <ul className="nav-menu">
          {props.menuItems.map((item, index) => (
            <li key={index}>
              <Link to={item.path} className="nav-menu--link" target={item.ext ? '_blank' : '_self'}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default NavMenu
